export type Fetch = (input: Request | string, init?: RequestInit | undefined) => Promise<Response>;

export const useRequestInit = (ssr = false, sessionCookie: string | null = null) => {
  const baseRequestInit = ssr ? {} : { credentials: 'include' as RequestCredentials };
  const baseHeaders = sessionCookie !== null
    ? ({ Cookie: sessionCookie } as HeadersInit)
    : {};

  return (requestinit: RequestInit): RequestInit => ({
    ...baseRequestInit,
    ...requestinit,
    headers: {
      ...requestinit.headers,
      ...baseHeaders,
    },
  });
};

export default function useApiClient(ssr = false, isApiResponse = false) {
  return async function apiClient<T>(
    url: string,
    init: RequestInit | undefined = {},
    expectResponse = true,
    textResponse = false
  ): Promise<[T, boolean, string?]> {
    // Need to add as Fetch for tests to pass
    const fetcher = fetch as Fetch;

    try {
      const response = await fetcher(url, init);
      if (response.ok && !expectResponse) {
        return [{} as T, false];
      }

      if (response.ok && response.json && !textResponse) {
        const data = await response.json();

        if (isApiResponse) {
          const apiResponse = data as { success: boolean, result: T };

          return apiResponse.success
            ? [apiResponse.result, false]
            : [{} as T, true];
        }

        return [data as T, false];
      } else if (response.ok) {
        const data = (await response.text()) as T;

        return [data, false];
      } else {
        const errorMessage = await response.text();
        return [{} as T, true, errorMessage];
      }
    } catch (err) {
      console.error(err, 'error in client');
      return [{} as T, true];
    }
  };
}
