import useApiClient, { useRequestInit } from './apiClient';
import type { UserProfile } from '@user-service/features/userProfile/model/userProfile';
import type { Organization } from '@user-service/features/organization/model';
import type { UserSession } from '@user-service/features/userSession/model';
import type { FloorPlan } from '@user-service/features/organization/model';

export const useUserApi = (
  baseUrl: string,
  ssr = false,
  sessionCookie: string | null = null
) => {
  const apiClient = useApiClient(ssr);
  const getRequestInit = useRequestInit(ssr, sessionCookie);

  const deleteAvatar = async () =>
    await apiClient(
      `${baseUrl}/api/v3/user/profile/avatar`,
      getRequestInit({
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }),
      false
    );

  const uploadAvatar = async (image: File) =>
    await apiClient<string>(
      `${baseUrl}/api/v3/user/profile/avatar`,
      getRequestInit({
        method: 'POST',
        headers: {
          Accept: 'application/text',
          'Content-Type': image.type,
        },
        body: image,
      }),
      true,
      true
    );

  const updateUserProfil = async (profile: Partial<UserProfile>) =>
    await apiClient(
      `${baseUrl}/api/v3/user/profile`,
      getRequestInit({
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(profile),
      }),
      false
    );

  const setPassword = async (oldPassword: string, newPassword: string) =>
    await apiClient(
      `${baseUrl}/api/v3/user/password?${new URLSearchParams({ oldPassword, newPassword }).toString()}`,
      getRequestInit({
        method: 'PUT',
        headers: {
          Accept: 'application/json',
        },
      }),
      false
    );

  const setXtoolPassword = async (newPassword: string) =>
    await apiClient(
      `${baseUrl}/api/v3/user/xtool-password`,
      getRequestInit({
        method: 'PUT',
        headers: {
          Accept: 'application/json',
        },
        body: JSON.stringify({ newPassword }),
      }),
      false
    );

  const getUserProfile = async () =>
    await apiClient<UserProfile>(
      `${baseUrl}/api/v3/user/profile`,
      getRequestInit({
        method: 'GET',
      })
    );

  const getOrganizationById = async (id: number) =>
    await apiClient<Organization>(
      `${baseUrl}/api/v3/user/organization/${id}`,
      getRequestInit({
        method: 'GET',
      })
    );

  const updateLoginHistory = async (id: number) =>
    await apiClient<UserSession>(
      `${baseUrl}/api/v3/user/session/login_history/${id}`,
      getRequestInit({
        method: 'PUT',
      }),
      false, 
      false
    );

  const updloadFloorPlan = async (floorPlan: File, orgId: number) => {
    const formData = new FormData();
    formData.append('file', floorPlan);

    return await apiClient<void>(
      `${baseUrl}/api/v3/user/organization/${orgId}/floor-plan`,
      getRequestInit({
        method: 'PUT',
        headers: {
          Accept: 'application/text',
        },
        body: formData,
      }),
      false,
    );
  };

  const getFloorPlans = async (orgId: number) => await apiClient<string[]>(
    `${baseUrl}/api/v3/user/organization/${orgId}/floor-plan`,
    getRequestInit({
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })
  );

  const getFloorPlansData = async (orgId: number) => await apiClient<FloorPlan[]>(
    `${baseUrl}/api/v3/user/organization/${orgId}/floor-plan-data`,
    getRequestInit({
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })
  );

  const deleteFloorPlans = async (orgId: number, files: string[]) => 
    await apiClient<void>(
      `${baseUrl}/api/v3/user/organization/${orgId}/floor-plan`,
      getRequestInit({
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ files }),
      }),
      false
    );

  return {
    setPassword,
    getUserProfile,
    getOrganizationById,
    updateUserProfil,
    uploadAvatar,
    deleteAvatar,
    updloadFloorPlan,
    getFloorPlans,
    deleteFloorPlans,
    getFloorPlansData,
    updateLoginHistory,
    setXtoolPassword,
    queryKeys: {
      userProfile: 'USER_PROFILE'
    }
  };
};
