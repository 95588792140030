import { createBrowserClient } from '@supabase/ssr';
import { type Database } from '@wision/supabase';

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  const supabase = createBrowserClient<Database>(config.public.supabaseUrl, config.public.supabaseAnonKey, {
    cookieOptions: {
      domain: config.public.publicDomain,
    }
  });

  return {
    provide: {
      supabase
    }
  };
});
